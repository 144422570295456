.navbar {
  background: rgb(255, 255, 255);
  width: 100%;
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
    top: 0;
    z-index: 10;
}
#NavSell{
  display: none;
}

#navBtn{
  margin-top: 20px;
  margin-left: 7px;
  padding: 7px;
  width: 94%;
  font-size: 18px;
  background-color: #FF5E00;
  color: #fff;
  border: none;
  border-radius: 4px;
}

#chatIcon{
  width: 32px; 
  height: 32px; 
  color: #002D65;
}



#Menu__Icon{
  margin-left: -10px;
  margin-top: 3px;
 width: 30px;
 height: 30px;
}

.login__signUp{
  display: flex;
}

#navLogin{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 7px;
  width: 90%;
  font-size: 18px;
 background-color: #ffffff;
  color: #FF5E00;
  border: none;
  border-radius: 400px;
}
#navSignUp{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 7px;
  width: 90%;
  font-size: 18px;
  background-color: #FF5E00;
  color: #fff;
  border: none;
  border-radius: 400px;
}


.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  width: 240px;
  margin-top: 5px;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 2px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: #002D65;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  transition: transform 450ms;
  font-size: large;
}

.nav-links:hover {
  transform: scale(1.10);
}

#Logout{
  border: none;
  color: #FF5E00;
  background-color: white;
  border: 1px solid #FF5E00;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px;
}

#Logout:hover, #login:hover {
  color: #ffffff;
  background-color: #FF5E00;
}

#signUp{
  border: none;
  color: #ffffff;
  background-color: #FF5E00;
  border: 1px solid #FF5E00;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px;
}

#login{
  border: none;
  color: #FF5E00;
  background-color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px;
}

#signUp:hover {
  color: #FF5E00;
  background-color: #ffffff;
}

#userName{
  font-weight: lighter;

}

.fa-bars {
  color: rgb(0, 0, 0);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

#NavSell{
  height: 40px;
  width: 40px;
  display: none;
  margin-top: 20px;
  font-weight: bold;
  color: #ffffff;
  background-color: #FF5E00;
  border: 1px solid #FF5E00;
}

#Menu__Icon{
  height: 28px;
  width: 28px;
  margin-top: -20px;
  margin-bottom: 10px;

}

.headerIcons__wrapper{
 display: none;
}
.header__icons{
  display: none;
}

#atMobile{
  display: none;
}

#watchLink{
  text-decoration: none;
  list-style-type:none;
}



@media screen and (max-width: 995px) {

  #atFull{
    display: none;
    text-decoration: none;
  }


  #atMobile{
    display: inline;
    text-decoration: none;
  }
  .navbar{
    height: 50px;
  }

  .NavbarItems {
    display: none;
  }

  .headerIcons__wrapper{
    justify-content: end;
    margin-right: 1rem;
    display: flex;
      position: absolute;
      top: 8px;
      right: 40px;
  }
  .header__icons{
      margin-left: 10px;
      display: inline;
  }

  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;

    width: 180px;
   margin-top: -3px;
   margin-left: -45px;
  }

  #Logout{
    border: none;
  }

  .nav-menu {
  
    display: none;
  }

  .nav-menu.active {
    background: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #FF5E00;
    color: #ffffff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #002D65;
  }

  .fa-times {
    color: #353332;
    font-size: 2rem;
  }


  

  .btn {
    display: none;
  }
}


@media screen and (max-width: 520px) {
  #NavSell{
    display: block;
    margin-left: 190px;
    margin-top: 0px;
    width: 30px;
    height: 35px;
  }
}

@media screen and (max-width: 430px) {
  #NavSell{
    display: none;
  }
}