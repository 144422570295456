.listing__expand{
    margin-left: 0px;
    padding: 5px;
}



.posted__usr{
    display: flex; 
}

#descTab{
    white-space: pre-line;
}

.submit__bid{
    display: flex;
}

#product__title{
    word-break: break-word;
}




.posted__usrDetails{
    cursor: pointer;
}
#usr_avatar{
  cursor: pointer;
  margin-top: 17px;
  margin-left: 5px;
  text-transform: uppercase;
  background-color: white;
  color: black;
  border: 1px solid #FF5E00;
}

#usr__name{
    margin-bottom: -1px;
    margin-left: 10px;
}

#usr__listings{
    
    color: #FF5E00;
    font-weight: bolder;
    font-style: italic;
    margin-left: 10px;
}

.usr__Reviews{
    display: flex;
    margin-top: 0px;
}

.picture__dialog{
    background: transparent
}



#numberOfReviews{
    margin-top:10px;
    margin-left: 5px;
}

.ExpImageSlider{
    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 2/1;
    object-fit: contain;
    height: 500px;
    
}

.fullImage{
    width: 100%;
  height: auto;

}

#expCarousel{
    margin-top: 20px;
    width: 600px
}



.aboutTheList{
    display: flex;
}

.bidding__area{
    
    width: 550px;
    background-color: #ffffff;
    border: 1px solid rgba(0,0,0,0.1);
   
    border-radius: 9px;
    padding: 3px;
    height: fit-content;
    min-height: 300px;
}

#Chip{
    margin-left: 20px;
    margin-top: -5px;
    margin-bottom: 8px;
}

.shipping__returns{
    display: block;
}

.listing__details{
    margin-left: 30px;
}

#winning__bid{
    margin-left: 25px;
}

#winning__time{
    margin-left: 25px;
    background-color: #002d65;
    padding: 8px;
    color: white;
    width: 13rem;
    border-radius: 4px;
    border: none;
    display: flex;
}

#product__title{
    word-break: break-word;
    white-space: normal;
    overflow: auto;
    text-overflow: inherit;

  
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

#current__price{
    margin-left: 5px;
    margin-top: -5px;
    font-size: 26px;
    color: #002d65;
    word-break: break-word;
    font-family: 'Righteous', cursive;
}

#current__bids{
    font-size: small;
    font-style: italic;
    color: #FF5E00;
    cursor: pointer;
}




.bid__form{
    display: flex;
}

#bids{
    display: flex;
    margin-bottom: -20px;
    margin-top: -23px;
    font-style: italic;
    color: #FF5E00;
    cursor: pointer;
}

#bidders{
    margin-left: 2px;
    font-style: italic;
    color: #FF5E00;
    cursor: pointer;
    text-decoration: underline;
}

#bidsNo{
 margin-left: 5px;
 margin-top: 21px;
}

#price{
    margin-left: 25px;
    display: flex;
   font-size: 17px;
    color: #FF5E00;
}

.biddingProcess{
    display: flex;
}

#inputThebid{
    margin-left: 25px;
    padding: 10px;
    border: 1px solid #FF5E00;
    font-size: large;
    width: 200px;
}

#placeTheBid{
    margin-left: 25px;
    margin-right: 15px;
    padding: 11px;
    font-size: large;
    color: #ffffff;
    background-color: #FF5E00;
    border: #FF5E00;
    width: 190px;
    cursor: pointer;
}

#chat__btn{
    margin-top: 11px;
    margin-left: 25px;
    padding: 11px;
    font-size: large;
    color: #ffffff;
    background-color: #FF5E00;
    border: #FF5E00;
    width: 90%;
    border-radius: 3px;
    cursor: pointer;
  
}

#chat__btn2{
    margin-top: 11px;
    margin-left: 25px;
    padding: 11px;
    font-size: large;
    color: #ffffff;
    background-color: #FF5E00;
    border: #FF5E00;
    width: 80%;
    border-radius: 3px;
    cursor: pointer;
}

#chat__lock{
    margin-left: 22px;

}

#modal__btn{
   color: #FF5E00;
}

.Seller__Com{
    word-break: break-word;
    white-space: pre-line;
    margin-left: 10px;
}

.seller__com__wrapper{
    border: 1px solid rgba(0,0,0,0.1);
    background: #fafafa;
    margin-top: 10px;
    padding: 4px;
    border-radius: 8px;
}
#com__name{
    
    margin-left: 10px;
    margin-top: 24px;
}


.addToWatchList{
    margin-left: 25px;
    cursor: pointer;
}





#descTab{
    word-break: break-word;
    min-height: 250px;
}

#report__btn{
    
    font-weight: bolder;
    border: 1px solid rgba(0,0,0,0.1);
   padding: 7px;
    background: white;
    padding-bottom: 12px;
    padding-right: 12px;
    margin-top: 12px;
    border-radius: 12px;
    color: rgb(179, 0, 0);
    cursor: pointer;
}

@media screen and (max-width: 1197px){
    .bidding__area{
        width: 500px;
    }

    #winning__time{
        margin-left: 0px;
    }
}

@media screen and (max-width: 1160px){

   
    
    
    .bidding__area{
        
        border: none;
        height: fit-content;
        flex: 1;
        width: 100%;
        box-shadow: none;
        padding: 0px;
    }

    #Chip{
        margin-left: 0px;
    }

    #price{
        margin-left: 0px;
    }
    .ExpImageSlider{
        max-inline-size: 100%;
        block-size: auto;
        aspect-ratio: 2/1;
        object-fit: contain;
        height: 22rem;
    }

    #expCarousel{
        margin-top: 20px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .aboutTheList{
        display: block;
    }

    #chat__btn{
        margin-left: 0px;
        width: 100%;
    }
    #chat__btn2{
        margin-left: 0px;
        width: 100%;
    }
    
    #winning__bid{
        margin-left: 0px;
    }

    .addToWatchList{
        margin-left: 0px;
    }
    .bid__form{
        display: block;
        width: 100%;
       
    }
    #bid__Rank{
        list-style: upper-roman;
    }
    #inputThebid{
        width: 95%;
        margin-left: 0px;
    }
    #placeTheBid{
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
    }

    .submit__bid2{
        width: 100%;
    }

    
.dashed__line{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    width: 100%;
  
}
    .listing__details{
        margin-left: 4px;
    }
}