.listing{
    text-align: center;
}

.listing__title{
    font-size: xx-large;
    margin-top: 5px;
    text-align: center;
    color: #002d65;
    font-weight: bolder;
}

#blank__dots{
    color: #ffffff;
}

.select__Category{
    background-color: #ffffff;
    color: #FF5E00;
    padding: 5px;
    
    padding: 10px;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.sub__Category{
    background-color: #ffffff;
    color: #FF5E00;
    padding: 5px;
    border: 1px solid #FF5E00;
    text-align: center;
}

.form__button{
    padding: 12px;
    width: 210px;
    color: #002d65;
    background-color: white;
   
    border: none;
    font-weight: bolder;
    box-shadow:  1px 2px 4px grey;
    margin-left: 5px;
    margin-top: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
}

.sub__form__button{
    
    width: 300px;
    height: 50px;
    color: white;
    background-color: #FF5E00;
    border: none;
    margin-left: 5px;
    margin-top: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    
}






#list__icons{
    margin-left: 7px;
}

@media screen and (max-width: 460px){
    .form__button{
        font-size: small;
        width: 150px;

    }
}


@media screen and (max-width: 455px){

    .form__button{
        margin-top: -1px;
    }

    .sub__form__button{
        margin-top: -1px;
    }

}
