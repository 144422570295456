 
.header {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 0.5rem 1rem;
    background-image: linear-gradient(to right, #ff5e00, #002d65);
    color: #fff;
    margin-bottom: 1rem;

  }
  
  .ad_Title{
    margin-left: 10px;
  }
  
  .header a {
    color: #fff;
    text-decoration: none;
  }


  
  .header-title {
    font-size: 1.2rem;
    font-weight: normal;
  }
  
  .header-title::after {
    content: ' ▸ ';
    padding: 0 0.5rem;
  }
  
  .header-subtitle {
    font-size: 1.2rem;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .search-panel {
    display: flex;
  }
  
  .search-panel__filters {
    flex: 1;
  }
  
  .search-panel__results {
    flex: 3;
  }
  
  .searchbox {
    margin-bottom: 2rem;
  }
  
  .pagination {
    margin: 2rem auto;
    text-align: center;
  }
  
  
  .ais-NumericMenu-list{
    list-style: none;
  }
  
  .ais-SearchBox-form{
    display: flex;
    align-items: center;

    border-radius: 5px;
    padding: 10px;

  }
  .ais-SearchBox-input{
    border: 1.8px solid rgba(0,0,0,0.1);
    outline: none;
    padding: 13px;
    border-radius: 5px;
    font-style: bold;
    background-color: white;
    width: 60rem;
    background-image: url(../images/icon-search.png);
    background-position: 8px center;
    background-size: 20px;
    background-repeat: no-repeat;
    padding-left: 40px;
    flex: 1;
  }
  
  .ais-SearchBox-submit{
    background-color: #ffffff; /* Primary color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 5px;
    display: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ais-SearchBox-submit:hover {
    background-color: #001a3c; /* Slightly darker shade of primary color */
  }
  
  .ais-SearchBox-submitIcon{
    display: none;
  }
  
  .ais-SearchBox-reset{
    display: none;
  }

.ais-Hits-list{
    list-style: none;
}  

.products__uiCard{
    display: flex;
    background-color: #ffffff;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    margin-left: -25px;
    width: 100%;
    cursor: pointer;
}  

.search__cardDetails{
  margin-left: 10px;
}

.search__image{
    width: 14rem;
    height: 14rem;
    border-radius: 8px;
}

.searchDescription{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
  margin-left: 10px;
  font-weight: lighter;
}

.SearchSeller{
  display: flex;
}

.SearchSellerAvatar{
  margin-top: 18px;
}

.SearchSellerDetails{
  margin-left: 10px;
  
}

.ais-Pagination-list{
  list-style: none;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right, #ff5e00, #002d65);
  padding: 20px;
  border-radius: 7px;
  
  
}

.ais-Pagination-link{
  padding: 20px;
  font-weight: bolder;
  color: #ffffff;
}

.ais-Pagination-link--selected{
  color: #002d65;
  font-weight: bold;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 8px;
  font-weight: bolder;
}

#SearchSellerDate{
  margin-top: 5px;
}
#SearchSellerName{
  margin-bottom: -0px;
}

@media screen and (max-width: 1186px){
  .search-panel__filters{
    display: none;
  }

  .header-title{
    display: none;
  }
}


@media screen and (max-width: 1173px){
  .header-subtitle{
    display: none;
  }

  
}



@media screen and (max-width: 1346px){
  .header-title{
    display: none;
  }
}

@media screen and (max-width: 1002px){
  .ais-SearchBox-input{
    width: 55rem;
    
  }
}

@media screen and (max-width: 716px) {
  .products__uiCard{
    height: fit-content;
  }
}


@media screen and (max-width: 915px){
  .ais-SearchBox-input{
    width: 49rem;
    
  }
  .ais-Pagination-list{
    width: 46rem;
    margin-left: 10px;
  }
}


@media screen and (max-width: 823px){
  .ais-SearchBox-input{
    width: 43rem;
    
  }
  .ais-Pagination-list{
    width: 41rem;
    margin-left: 10px;
  }
}

@media screen and (max-width: 735px){
  .searchDescription{
    display: none;
  }
  .search__image{
    width: 10rem;
    height: 125px;
    border-radius: 8px;
}
.ad_Title{
  margin-left: 10px;
  word-break: break-word;
  font-size: small;
  white-space: normal;
  overflow: auto;
}

.ais-SearchBox-input{
  width: 38rem;
  
}
.ais-Pagination-list{
  width: 36rem;
  margin-left: 10px;
}
}

@media screen and (max-width: 656px){
  .ais-SearchBox-input{
    width: 32rem;
    
  }
  .ais-Pagination-list{
    width: 30rem;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

}

@media screen and (max-width: 554px){
  .ais-SearchBox-input{
    width: 28rem;
    
  }
  .ais-Pagination-list{
    width: 26rem;
  }
}

@media screen and (max-width: 514px){
  .ais-SearchBox-input{
    width: 21rem;
    
  }
  .ais-Pagination-list{
    width: 19rem;
  }
}