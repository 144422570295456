#watchTitle{
   color: #002D65;
   font-weight: bolder;
   font-family: 'Concert One', cursive;
   font-size: x-large;
}

.user__watching{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px #00000029;
    height: fit-content;
    width: 95%;
    padding: 10px;
    margin-top: 8px;
    display: flex;
    border-radius: 8px;
    margin-left: 10px;

}

#no__items{
    text-align: center;
}


.profile__tab{
    width: 100%;
}

.watch__img{
    flex: 0.5;
}

.watch__img > img{
  width: 200px;
  height: 200px;
  
}

.watch__img{
    flex: 0.1;
  
}

.watch__info{
    margin-top: -15px;
    flex: 0.8;
    margin-left: 10px;
}
.remove__btn2{
    flex: 0.1;
   
}

#watch__title{
    word-break: break-all;
}

#watch__itemDesc{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 600px;
    font-weight: lighter;
    word-break: break-all;
}


.watch__time{

}

.watchAuch{
    padding: 3px;
    border-radius: 4px;
    background-color: #FF5E00;
    color: #ffff;
    width: 10.5rem;
    
    margin-top: -10px;
}

.WatchbuyNow{
margin-top: 0px;

    padding: 3px;
    border-radius: 4px;
    background-color: #002D65;
    color: #ffff;
    width: 10.5rem;
    
    margin-top: -10px;
}

.watch__location{

}

@media screen and (max-width: 958px){
    .user__watching{
        margin-left:0px;
    }


    #watch__itemDesc{
       width: 200px;
    }

    .user__watching{
        display: block;
    }
    .remove__btn2{
        margin-left: -2px;
    }

    .watch__img > img{
       width: 100%;
       height: auto;
       max-height: 500px;
    }

    

    .remove__btn2 > button {
        margin-left: -20px;
    }
}



.remove__btn2 > button {
    border: none;
    color: white;
    background-color: rgb(184, 0, 0);
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
}