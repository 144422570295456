.listing__form{
    border: 2px solid #FF5E00;
    margin-top: 20px;
    text-align: center;
}

#required{
    color: red;
}



.form__title{
    text-align: center;
}

.upload__field::-webkit-file-upload-button{
    visibility: hidden;
    color: #FF5E00;
    font-size: 0px; 
}

.location__places{
    display: block;
    margin: auto;
   max-width: 490px; 
  width: 40%;
  min-width: 300px;
  border: none;
  box-shadow: 1px 2px 4px grey;
  padding: 10px;
  border-radius: 12px;
  font-family: 'Hubballi', cursive;
}

#fashionToggle{
    width: 20rem;
}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .upload__btn {
    border: none;
    color: white;
    background-color: #002d65;
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
  }

  .startOver{
    border: none;
    color: white;
    background-color: rgb(184, 0, 0);
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }



.actual__photos{
    background: transparent;
    width: 60px;
    height: 60px;
    border:1px solid #002d65;
    position: relative;
    text-align: center;
    color: white;
    cursor: pointer;
    margin-left: 13px;
}

.delete__pic{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -4px;
  color: #FF5E00;
  font-weight: bolder;
  font-size: xx-large;
  cursor: pointer;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FF5E00;
}

.actual__photos > .delete__pic {
    display: none;
}

.actual__photos:hover > .delete__pic{
    display: inline;
}

.photo__display{
    display: flex;
  
  }

.upload__field::before {
    content: 'Select Images +';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #FF5E00;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    background-color: #ffffff;
    color: #FF5E00;
  }


  .upload__field:hover::before {
    border-color: rgb(255, 255, 255);
  }
  .upload__field:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }

  #submit__images{
    background-color: #FF5E00;
    color: #ffffff;
    padding: 5px;
    border: 1px solid #FF5E00;
    padding: 10px;
    font-weight: 700;
    font-size: 10pt;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
    width: 150px;
    text-align: center;
    margin-left: 1rem;
    padding: 5px 8px;
    border-radius: 3px;
  }

.form__subTitle{
    margin-left: 0rem;
}

.input__label {
    width:  100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: block;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: large;
}

.input__title{
    width: 30rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
}

.condition__select{
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
}

.item__description{
    resize: none;
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    height: 15rem;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
}

.condition__description{
    resize: none;
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    height: 15rem;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
}

.sellingStyle__select{
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
}

.duration__select, .returns__select{
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
    cursor: pointer;
    
   
}

#validation__Errors{
    color: white;
    background-color: rgb(184, 0, 0);

    font-weight: bolder;
    width: 100%;
    text-align: center;
}

.top__select{
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: none;
    font-size: large;
    box-shadow:  1px 2px 4px #FF5E00;
    cursor: pointer;
    color: #002d65;
    font-weight: bolder;
    margin-top:35px;
}

.input__quantity, .input__startingPrice, .input__buyItNowPrice, .location__inputs, .ship__cost{
    width: 30rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
    -moz-appearance: textfield;
}

.submit__btn{
    width: 32rem;
    margin-left: 0rem;
    padding: 15px;
    border-radius: 8px;
    border: none;
    background-color: #FF5E00;
    margin-top: 3rem;
    font-size: large;
    margin-bottom: 3rem;
    color: #ffffff;
    cursor: pointer;
}

.check__boxes{
    margin-top: 30px;
}

.optional{
    color: grey;
    font-size: small;
}

.image__error{
    color: red;
}

.progress-bar{
    height: 5px;
    background: #FF5E00;
    margin-top: 20px;
  }


@media screen and (max-width: 580px){

    .condition__description, .item__description{
        width: 18rem;
        height: 12rem;
    }
    
    .condition__select, .duration__select, .top__select, .returns__select, .sellingStyle__select{
        width: 20rem;
    }

    .input__title, .input__quantity, .input__startingPrice, .input__buyItNowPrice, .location__inputs, .ship__cost, .submit__btn{
        width: 18rem;
    }

}