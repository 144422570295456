.typeOfAd{
    display: flex;
    margin: auto;
  width: 60%;

  padding: 10px;
}

.normal__list, .premium__list{
    margin: auto;
    width: 1000px;
   
    padding: 10px;
}

.normal__list{
    background-color: #fff;
    border: none;
    box-shadow: 1px 2px 4px grey;
    margin-right: 8px;
    border-radius: 9px;
}

.premium__list{
  background-color: #fff;
  border: none;
  box-shadow: 1px 2px 4px grey;
  margin-left: 8px;
  border-radius: 9px;
}

.post__header1{
    border: none;
    color: #fff;
    background-color: #002d65;
  
    margin-right: 8px;
    border-radius: 7px;
    padding: 6px;
}

.post__header2{
    border: none;
    color: #fff;
    background-color: #d4af37;
    padding: 6px;
    margin-right: 8px;
    border-radius: 7px;
}

.submit2{
    border: none;
    color: #fff;
    background-color: #d4af37;
    padding: 15px;
    margin-right: 8px;
    border-radius: 7px;
    font-size: medium;
    font-weight: bold
}

.submit1{
    border: none;
    color: #fff;
    background-color: #002d65;
    padding: 15px;
    margin-right: 8px;
    border-radius: 7px;
    font-size: medium;
    font-weight: bold
}

.benefits{
    font-weight: bolder;
    text-align: left;
}

.benefits > ul{
   list-style: none;
}