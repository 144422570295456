.profile__banner{
    margin-top: 20px;
    width:100%;
    height:270px;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 3px;
    display:flex;
    margin-bottom: 3px;
    padding-top: 5px;
}

.profile-name{
    margin-top: 80px;
    margin-left: 70px;
    margin-bottom: 3px;
}

.verify_btn{
    background: #002d65;
    color: white;
    padding: 5px;
    border: none;
    font-weight: bolder;
    border-radius: 12px;
    margin-left: 5px;
    padding-bottom: 10px;
    margin-bottom: -10px;
    cursor: pointer;
}

.verify_formField{
    margin-top: 10px;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.1);
}

.Verify_submitBtn{
    background: #FF5E00;
    color: white;
    padding: 15px;
    border: none;
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
    width: 8rem; 
}
.Verify_verifyBtn{
   background: #002d65;
    color: white;
    padding: 15px;
    border: none;
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
    width: 8rem;
}

.verify_title{
    text-align: center;
}

.user__info{
    margin-left: 80px;
}

#aboutSection{
    word-break: break-word;
}

.profile__info{
    display: flex;
}
.profile__sidebar{
    width: 70%;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
    
}

.bio{
    background-color: #ffffff;
    margin-top: -15px;
}

.profile__btns{
    display: flex;
    
}
.profile__rating{
    margin-left: 25px;  
}
.bio2{
    margin-left: 25px; 
    
}

#linear{
    margin-top: -1px;
    width: 6.8rem;
   margin-left: 26px;
}
.sell__btn{
    width: 110px;
    height: 40px;
    color: white;
    background-color: #FF5E00;
    border: none;
    margin-left: 25px;
    margin-right: 5px;
    margin-top: 8px;
    border-radius: 4px;
    margin-bottom: 10px;

    cursor: pointer;
    text-align: center
}

.sell__btn2{
    width: 110px;
    height: 40px;
    color: white;
    background-color: #FF5E00;
    border: none;
    margin-right: 25px;
    margin-left: 5px;
    margin-top: 8px;
    border-radius: 4px;
    margin-bottom: 10px;

    cursor: pointer;
    text-align: center
}

.sell__btn3{
    width: 110px;
    height: 40px;
    color: white;
    background-color: #FF5E00;
    border: none;
    margin-right: 25px;
    margin-left: -10px;
    margin-top: 8px;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center
}

.delete__profile{
    position: relative;
    bottom: 0;
}
#deleteAccount{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.deleteDivider{
    padding: 1px;
    width: 99%;
    background-color: red;
    margin-top: 50px;
}

    /*--------------------upload profile image-----------------*/

    .upload-btn-wrapper2 {
        position: relative;
        overflow: hidden;
        display: inline-block;
      }
      
      .btn2 {
        width: 110px;
        height: 40px;
        color: white;
        background-color: #FF5E00;
        border: none;
        margin-left: 25px;
        margin-right: 5px;
        margin-top: 8px;
        border-radius: 4px;
        margin-bottom: 10px;
    
        cursor: pointer;
        text-align: center
      }
      
      .upload-btn-wrapper2 input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }



.profile__img{
    width:250px;
    height:250px;
    margin-top: 8px;
    margin-left: 20px;
    box-shadow: 0px 3px 29px #00000029;

}

.reportUser{
    position:absolute;
    right:20px;
}

#report__btn2{
    
    font-weight: bolder;
    border: 1px solid rgba(0,0,0,0.1);
   padding: 7px;
    background: white;
    padding-bottom: 12px;
    padding-right: 12px;
    margin-top: 12px;
    border-radius: 12px;
    color: rgb(179, 0, 0);
    cursor: pointer;
}

.profile__banner  > img {
   border-radius: 10%;
}

.profile__rating{
    display: flex;
}

.profile__ratings{
    margin-top: 18px;
}
 
.profile__reviews{
    margin-left: 9px;
    margin-top: -9px;
}

#rate__stars{
    margin-left: 40px;
    margin-top: 90px;
}
@media screen and (max-width: 662px){
    .profile__banner{
        display: block;
        height: fit-content;
        min-height: 350px;
    }

    .profile__btns{
        display: block;
    }

    .sell__btn, .sell__btn2, .sell__btn3, .btn2, .upload-btn-wrapper2 {
        margin-right: 1px;
        margin-left: 1px;
        width: 100%;
    }
    #linear{
     width: 100%; 
     margin-left: 0px;  
    }

    .profile-name{
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
        text-align: center;
    }

    .reportUser{
        position: sticky;
        left: 30px;
        right: 0px;
        margin-top: -10px;
    }



    /*--------------------upload profile image-----------------*/




    #report__btn2{
        border: none;
    }

.profile__banner > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
margin-top: 10px;
    }

    .user__info{
        text-align: center;
        margin-left: 0;
    }

}
@media screen and (max-width: 998px){

    .profile__info{
        display: block;
    }

    .profile__sidebar{
        width: 100%;
    }

    .profile__sidebar{
        padding-top: 10px;
        margin-top: 10px;
    }

    

}