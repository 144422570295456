
.home__page{
   
    position: relative;
    border: 5px solid white; 
}

.location__select{
    background: #FF5E00;
    border: none;
    padding: 12px 24px;
    color: white;
    font-size: large;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;

}

#Carousel{
    
    margin-top: 50px;
}

.banner__image{
    width: 100%;
    height: auto;
}



#location__icon{
    background: #FF5E00;
    border: none;
    padding: 6.5px;
    color: white;
    font-size: x-large;
    width: 35px;
    height: 35px;
}

#card__location{
    color: #FF5E00;
}
.btn-group > #home__cat__Link > button {
    background-color: #ffffff; 
    border: 1px solid #FF5E00; 
    color: #FF5E00; /* White text */
    padding: 6px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    text-align: center;
    border-radius: 78px;
  }
 
.btn-group{
    margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}  

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Add a background color on hover */
.btn-group > #home__cat__Link >  button:hover {
    background-color: #FF5E00;
    color: white;
  }

.viewAll-container{
    margin: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.view-cat-button{
    padding: 9px;
    width: 30rem;
    border: 1px solid #FF5E00;
    cursor: pointer;
    color: #FF5E00;
    border-radius: 6px;
    background: white;
    position: relative;
    bottom: 20px;
    font-weight: bolder;
  
}  


@media screen and (max-width: 906px){
    .btn-group{
        display: none;
    }
}

@media screen and (max-width: 500px){
    .view-cat-button{
        padding: 5px;
        width: 14rem;
        border: 1px solid #FF5E00;
        cursor: pointer;
        color: #FF5E00;
        border-radius: 6px;
        background: white;
    
        margin-bottom: 15px;
        font-weight: bolder;
      
    } 
}
