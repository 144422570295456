.containerSSS{
    margin: 0;
    padding: 0;
    font-family: "montserrat",sans-serif;
    min-height: 100vh;

  }

  
  .containerSSS{
    width: 100%;
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    text-align: center;
    color: #343434
  }
  
  #oops{
      color: #343434;
  }

  .containerSSS > h1{
    font-size: 100px;
    margin: 0;
    font-weight: 900;
    letter-spacing: 20px;
     background-color: #002d65;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  
  .containerSSS > a{
    text-decoration: none;
    background: #ff5e00;
    color: #fff;
    padding: 12px 24px;
    display: inline-block;
    border-radius: 25px;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.4s;
  }
  
  .containerSSS > a:hover{
    background: #e55039;
  }
  




  










  
  .containerTTT{
    width: 100%;
  
   

    margin: auto;
  width: 50%;

  padding: 10px;
    color: #343434;
    margin-bottom: 100px;
  }
  
  #oopsTTT{
      color: #343434;
  }

  .containerTTT > h1{
    font-size: 100px;
    margin: 0;
    font-weight: 900;
    letter-spacing: 20px;
     background-color: #002d65;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  
  .containerTTT > a{
    text-decoration: none;
    background: #ff5e00;
    color: #fff;
    padding: 12px 24px;
    display: inline-block;
    border-radius: 25px;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.4s;
  }
  
  .containerTTT > a:hover{
    background: #002d65;
  }
  