:root {
  --primary: #FF5E00;
}

.btn {
  padding: 12px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: 150px;
  background-color: var(--primary);
  letter-spacing: 2px;
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: var(--primary);
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
