
  *{
    font-family: 'Montserrat', sans-serif;
   
  }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 906px){
  h3{
    font-size: medium;
  }
  h2{
    font-size: large;
  }
  h1{
    font-size: x-large;
  }
}