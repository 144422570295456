/*------------------------Chats bar CSS------------------------------------- */          

.chat{
    flex: 0.90;
    display: flex;
    flex-direction: column;
    height:80vh;
    
}

.backToChats{
    margin-right: 10px;
    margin-top: 5px;
}
/* width */
.chat__body::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .chat__body::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .chat__body::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .chat__body::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }





.chat__header{
    padding: 9px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid #ececec;
}

#header__Name{
    
    color: rgb(99, 98, 98);
}
#header__Title{
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.chat__headerInfo{
    flex: 1;
    padding-left: 20px;
    margin-top: -16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat__body{
    flex: 1;
    background-color: white;
    padding: 30px;
    overflow-y: scroll;
    border-bottom: 1px solid #ffffff;
    scrollbar-color: #7f7f7f white; /* thumb and track color */
    scrollbar-width: thin;
    
    
}

.chat__message{
    position: relative;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    background-color:#FF5E00;
    margin-top: 30px;
    color: white;
    overflow: auto;
    word-break: break-word;
    width: fit-content;
   
    white-space: pre-line;
 
}

.chat__name{
    position: absolute;
    top: 0px;
    font-weight: 800;
    font-size: xx-small;
    color: black;
}
.chat__timestamp{
    margin-left: 10px;
    font-size: x-small;
    font-weight:lighter;
}

.chat__receiver{
    margin-left: auto;
    background-color: #e2e2e2;
    color: black;
}

.chat__footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2px;
    border-top: 1px solid #d3d3d3;
}

.chat__footer > form{ 
    width: 100%;
    display: flex;
    margin-top: 56px;
    
}

.chat__input {
    flex: 1;
    border: 1px solid #FF5E00;
    padding: 10px;
    resize: none;
    width: 100%;
    height: 20px;
    max-height: 50px;
    border-radius: 90px;
    margin-bottom: 5px;
}


.chatSubmit__btn{
    background-color: #FF5E00;
    width: 102px;
    font-size: larger;
    border: none;
    color: white;
    margin-left: 4px;
    cursor: pointer;
    padding: 7px;
    border-radius: 90px;
    margin-bottom: 5px;
}


/*------------------------Side bar CSS------------------------------------- */          


.sidebar{
    flex: 0.3;

    height: 10px;
}



.sidebar_header{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right:1px solid #ccc; 
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
}

.sidebar_chats{
    flex: 1;
    overflow-y: scroll;
   
    height: 80vh;
    scrollbar-color: #7f7f7f white; /* thumb and track color */
    scrollbar-width: thin;
    border-right:1px solid #ccc; 
    border-bottom:1px solid #ccc; 
    
    
    
}
/* width */
.sidebar_chats::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sidebar_chats::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .sidebar_chats::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .sidebar_chats::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }







.sidebarChat{
    display: flex;
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ececec;
 
}



.sidebarChat:hover{
    background-color: #d8d8d8;
    color: white;
}

.sidebarChat__info{
    margin-top: -5px;
    margin-left: 10px;
    text-decoration: none;
    color: black;
}

a{
    text-decoration: none;
    color: black; 
}

.sidebarChat__info > p{ 
    margin-top: -10px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#sideBar_Avatar{
    margin-top: 5px;
}
.sidebarChat > #AlertBadge{
    margin-top: 18px;
    margin-right: 5px;
}


















@media screen and (max-width: 990px){

    .chatSubmit__btn{
        background-color: #FF5E00;
        width: 52px;
        font-size: larger;
        border: none;
        color: white;
        margin-left: 4px;
        cursor: pointer;
        padding: 7px;
    }

    .chat__input {
        flex: 1;
        border: 1px solid #FF5E00;
        padding: 10px;
        resize: none;
        width: 100%;
        height: 20px;
        max-height: 50px;
     
    }
    
    

   
   

}