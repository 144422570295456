.profile__tabs{
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 2px;
    font-weight: bolder;
    width: auto;
    flex: 1;
   
}

.user__listings{
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0,0,0,0.1);
    height: fit-content;
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    display: flex;
    border-radius: 8px;
    margin-left: -10px;
}


.profile__tab{
    width: 100%;
}

.userListing__img{
    width: 270px;
    height: 200px;
    display: flex;
    border-radius: 2px;
}

.listing__img > img{
    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 2/1;
    object-fit: cover;
    height: 200px;
    width: 260px;
    border-radius: 5px;
}

.list__info{

    margin-top: -20px;
    margin-left: 10px;
}

.pro__time{

}

.Auch{
    padding: 3px;
    border-radius: 4px;
    background-color: #FF5E00;
    color: #ffff;
    width: 10.5rem;
    
    margin-top: -10px;
}

#tab__itemDesc{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    font-weight: lighter;
    word-break: break-all;
}

.buyNow{
margin-top: 0px;
    margin-left: 0px;
    padding: 3px;
    border-radius: 4px;
    background-color: #002D65;
    color: #ffff;
    width: 10.5rem;
    
    margin-top: -10px;
}

#profileTitle{
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
}

.pro__location{

}

@media screen and (max-width: 800px){

    .user__listings{
        display: block;
    }
    .remove__btn{
        margin-left: -2px;
    }

    .listing__img > img{
       width: 100%;
       height: auto;
       max-height: 500px;
    }

    
   

    .remove__btn > button {
        margin-left: -20px;
    }
}

.remove__btn{
    
    margin-top: 1px;
    
}

.remove__btn > button {
    border: none;
    color: white;
    background-color: rgb(184, 0, 0);
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
}