.catagory__container{
    display: flex;
}

.category__wrapper{
    padding: 1rem;
    flex: 0.7;
}

.otherCat{
    flex: 0.3;
    background-color: #ffffff;
}

#btn__toggle{
    height: 35px;
}

.card2{
    object-fit: contain;
    width: 100%;
    display: flex;
    min-height: 40px;
    transition: transform 450ms;
    margin-right: 8px;  
    box-shadow:  1px 2px 4px grey;
   padding: 5px;
   cursor: pointer;
   height: 200px;
   border-radius: 8px;
   margin-top: 10px;
}

#card__itemDesc{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400px;
    margin-left: 10px;
    font-weight: lighter;
}

.card2:hover{
    box-shadow:  1px 2px 3px #FF5E00;
}

.card__title2{
    margin-bottom: -2px;
    margin-left: 10px;
    margin-top: -1px;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.cat__info{
    display: block;
}

.card__price2{
    margin-left: 10px;
    color: #FF5E00;
    margin-bottom: 7px;
}

.card__shipping2{
    margin-left: 10px;
    color: #FF5E00;
    margin-bottom: 9px;
}

.bids__time2{
    display: flex;
    margin-top: -25px;
    
}

.card__time2, .card__bids2{
    margin-left: 10px;
   
}

.card__bids2{
    margin-top: 26px;
}

.card__time2{
    padding: 3px;
    border-radius: 4px;
    background-color: #FF5E00;
    color: #ffff;
}

.negotiable2{
    padding: 3px;
    border-radius: 4px;
    background-color: #002D65;
    color: #ffff;
    width: 10.5rem;
    margin-left: 10px;
}

.card__image2{
    max-inline-size: 100%;
  block-size: auto;
  aspect-ratio: 2/1;
  object-fit: cover;
  height: 200px;
  width: 260px;
  
 
}



.card__location2{
    margin-top: -12px;
    margin-left: 10px;
    color: #002D65;
}

.row__title__div2{
    margin-top: 50px;
}

.viewAll__button2{
    float: right;
    top: 10px; 
    right: 10px;
    position: relative;
    color: #ffffff;
    background-color: #FF5E00;
    font-size: medium;
    padding: 5px;
    padding-left: 18px;
    padding-right: 18px;
    transition: 100ms;
    cursor: pointer;
    font-weight: lighter;
}


.row__title{
    margin-bottom: -40px;
    font-weight: bolder;
    color: #002d65;
}


#search__icon{
    display: none;
}

.other-categories{
    color: #FF5E00;
    margin-left: 15px;

    cursor: pointer;
}

.other-categories:hover{
    text-decoration: underline;
    
}

.bottom__action{
    margin-top: 20px;
    text-align: center;
}

#viewMore__btn{
    padding: 10px;
    border: none;
    background-color: #002d65;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

#no__more{
    color: #002d65;
}

#responsive-cat{
    display: none;
}




@media screen and (max-width: 1130px){

    .category__wrapper{
        margin: auto;
        min-width: 20rem;
        width: 50%;
    }


    
    .category__toggle{
        margin: auto;
        width: 15.7rem;
     
    }

   

    .card2{
        height: fit-content;
        margin-left: 10px;
        padding-bottom: -12px;
        box-shadow:  1px 1px 3px grey;
       
        width: 100%;
    }

    .otherCat{
        display: none;
    }

    #card__itemDesc{
        display: none;
    }

    .card__title2{
        margin-bottom: -2px;
        margin-left: 10px;
        margin-top: -1px;
        word-break: break-word;
        font-size: small;
        white-space: normal;
        overflow: auto;
        text-overflow: inherit;
   
      
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }

    .card__location2{
        font-size: small;
        overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
   margin-top: 0px;
    }

    .card__image2{
        max-width: 9rem;
        height: 125px;
        border-radius: 8px;
        

    }
    .bids__time2{
       
        font-size: small;
        margin-top: -15px;
        margin-left: -10px;
        
    }
    .negotiable2{
        font-size: x-small;
        width: fit-content;
    }
    #separator2{
        display: none;
    }

    #responsive-cat{
        display: inline;
    }
}


@media screen and (max-width: 442px){
    .category__wrapper{
       margin-left: -13px;
    }
    .category__toggle{
        margin-left: 10px;
    }
    
}


