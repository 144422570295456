.first4{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, auto);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-left: 35px;
    margin-right: 35px;
    border-radius: 5px;
}

.firstBtns{
    width: 80%;
    margin-top: 1rem;
    margin-left: 35px;
    padding: 14px;
    border: none;
    background-color: white;
    box-shadow: 1px 2px 4px #ff5e00;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    color: #002d65;
}

.catHeader{
    text-align: center;
}

.catHeader > h2 {
    color: #002d65;
    margin-top: 2rem;
}

@media screen and (max-width: 700px){
    .first4{
        display: block;
        margin-left: 5px;
        margin-right: 5px;
    }

    .firstBtns{
        margin-left: 0px;
    }
}