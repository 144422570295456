
.chat__app{
  display: flex;
  place-items: center;
 
  
}

.chat__appBody{
  display: block;
  width: 100%;
  

}

body{
  margin: 0;
}

.Terms__header{
  text-align: center;
}

.Terms__body{
  padding: 10px;
  margin: auto;
  width: 80%;
  margin-top: -10px
}

#contact__help{
  color: rgb(12, 15, 231);
  text-decoration: underline;
}

.terms__list{
  list-style-type: lower-roman;
}


#customers2 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
}

#customers2 td, #customers2 th {
  border: 1px solid #ddd;
  padding: 8px;
}





#customers2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #002d65;
  color: white;
}

em {
  background: #002d65;
  font-style: normal;
  color: white;
}


