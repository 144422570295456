.modal__form{
    display: flex;
    width: 600px;
    height: 100px;
    margin-top: 30px;
}

#ModalClose{
   background: transparent;
   border: none;
}
#ModalCloseIcon{
   color: rgb(226, 81, 81);
}

#Modaltitle{
    margin-top: 20px;
    font-weight: bolder;
}

#MessageInput {
    width: 100%;
    height: 20px;
    padding: 10px;
    border: 1px solid #777777;
    resize: none;
    border-radius: 3px;
}

.hello{
    display: none;
}

#sendMessage{
    background-color: #FF5E00;
    border: none;
    color: white;
    padding: 12px;
    margin-left: 5px;
    font-weight: bolder;
    cursor: pointer;
}

@media screen and (max-width: 655px){

    .modal__form{
        width: 70px;
    }
  
}   