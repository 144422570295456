#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 16px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}



#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ff5e00;
  color: white;
}


















/*-----------------------------------------------------Car Table--------------------------------------*/


#customers2 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 50%;
  margin-top: 2rem;
  font-weight: bolder;
  
}


#customers2 > tr > td {
  padding: 16px;
}
#customers2 > tr > td:first-child {
  background-color: white;
  color: #ff5e00;
}

#customers2 > tr > td:nth-child(2){
  background-color: white;
  color: #002d65;
 
}

@media screen and (max-width: 1000px) {
  #customers2{
    width: 100%;
  }
}

