#card__row{
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 40px;
    margin-bottom: 10px;
    align-items: center;
    scroll-behavior: smooth;
    height: 450px;
}

#card__row::-webkit-scrollbar{
    display: none;
}

#card__row > #back__arr{
    display: none;
}

#card__row > #forward__arr{
    display: none;
}

#card__row:hover > #back__arr{
    display: inline;
}

#card__row:hover >  #forward__arr{
    display: inline;
}

.card{
    object-fit: contain;
    width: 17rem;
    
    min-height: 40px;
    transition: transform 450ms;
    margin-right: 8px;  
    box-shadow:  1px 2px 4px grey;
   padding: 5px;
   cursor: pointer;
   height: 360px;
   border-radius: 8px;
}

.card:hover{
    box-shadow:  1px 2px 3px #FF5E00;
}

.card__title{
    margin-bottom: -2px;
    margin-left: 10px;
    margin-top: -1px;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.card__price{
    margin-left: 10px;
    color: #FF5E00;
    margin-bottom: 7px;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card__shipping{
    margin-left: 10px;
    color: #FF5E00;
    margin-bottom: 9px;
}

.bids__time{
    display: flex;
    margin-top: -25px;
    
}

.card__time, .card__bids{
    margin-left: 10px;
   
}

.card__bids{
    margin-top: 26px;
}

.card__time{
    padding: 3px;
    border-radius: 4px;
    background-color: #FF5E00;
    color: #ffff;
}

.card__image{
    max-inline-size: 100%;
  block-size: auto;
  aspect-ratio: 2/1;
  object-fit: cover;
  height: 13rem;
    
}

.negotiable{
    margin-top: 0px;
    margin-left: 10px;
    padding: 3px;
    border-radius: 4px;
    background-color: #002D65;
    color: #ffff;
    width: 10.5rem;
}

.card__location{
    margin-top: -12px;
    margin-left: 10px;
    color: #000000;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.row__title__div{
    margin-top: 50px;
}

.viewAll__button{
    float: right;
    top: 10px; 
    right: 10px;
    position: relative;
    color: #ffffff;
    background-color: #FF5E00;
    font-size: medium;
    padding: 5px;
    padding-left: 18px;
    padding-right: 18px;
    transition: 100ms;
    cursor: pointer;
    transition: transform 450ms;
    font-weight: lighter;
}

.viewAll__button:hover{
    transform: scale(1.1);
}

.row__title{
    margin-bottom: -40px;
    font-weight: bolder;
    color: #002d65;
}

#fire__imoji{
    margin-left: 7px;
}

#search__icon{
    display: none;
}
#forward__arr{
 right: 0;
 position: absolute;
 padding: 10px;
 background-color: #002D65;
 color: rgb(255, 255, 255);
 border-radius: 50%;
 opacity: 0.8;
 cursor: pointer;
}

#back__arr{
  left: 0;
  position: absolute;
  padding: 10px;
  background-color: #002D65;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 0.8;
  cursor: pointer;
  margin-left: 5px;
}



#back__arr:hover, #forward__arr:hover{
    opacity: 1;
}

@media screen and (max-width: 540px){

    #back__arr, #forward__arr{
        display: none;
    }

    #card__row{
        overflow-x: auto;
    }

    #card__row > #back__arr{
        display: none;
    }
    
    #card__row > #forward__arr{
        display: none;
    }

    
}



@media screen and (max-width: 906px){

    .card{
        width: 14rem;
        height: 325px;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,0.1);
    }

    #search__icon{
        color: white;
        display: inline;
        margin-left: -10px;
        font-size: 18px;
    }

    #search{
        display: none;
    }

    #location__icon{
        display: none;
    }

    .location__select{
        display: none;
    }

    .search-button{
        width: 5%;
    }

    .search__bar{
        width: 300px;
    }
  
    

}