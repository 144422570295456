.signup__form__wrapper{
    border: 2px solid #FF5E00;
    text-align: center;
    width: 50rem;
    margin: auto;

}



#OrSignUp{
    border-bottom: 1px solid #FF5E00;
}

.logo{
    margin-top: 10px;
    width: 250px;
}

#reg__link{
    color: #FF5E00;
    cursor: pointer;
}


.social__signUp{
    display: flex;
    text-align: center;


}

.google__btn, .facebook__btn{
    margin-left: 12.5rem;
    padding: 9.5px;
    border: 1px solid #FF5E00;
    font-size: large;
    margin-bottom: 3rem;
    cursor: pointer;
    background-color: #FF5E00;
    color: white;
    width: 28rem;
}

.google__icon{
    width: 29px;
    border: 1px solid #FF5E00;
    padding: 6px;
    margin-right: -22.4rem;
}


.signup__submit{
    width: 31rem; 
    padding: 8px;
    color: white;
    background-color: #FF5E00;
    border: none;
    margin-left: 14px;
    margin-top: 20px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 44px;
}

.signUp__inputs{
    width: 30rem;
    margin-left: 1rem;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #FF5E00;
    font-size: large;
}

.signup__labels{
    width:  100%;
    padding: 2px 12px;
    margin: 8px 0;
    display: block;
    border-radius: 4px;
    box-sizing: border-box;
}

@media screen and (max-width: 800px){


    .signUp__inputs, .signup__submit {
        width: 17rem;
    }

    .signup__form__wrapper{
        width: 20rem;
    }

    .facebook__btn{
        margin-top: -30px;
        margin-left: 0rem;
    }

    .google__btn{
        width: 14rem;
        margin-left: 4rem;
    }
    .google__icon{
        margin-right: -5.4rem;
    }

}