.top__help{
background: #002d65;
height: fit-content;
 display: flex;
}


.about__header{
    flex: 0.2;
}
.about__us{
  flex: 0.8;
  color: white;
}

#help__subTitle{
    font-family: 'Concert One', cursive;
    font-family: 'Righteous', cursive;
    color: #002d65;
    font-size: xx-large;
}





.bottom_help{
    margin-left: 15px;
    margin-right: 15px;
}


.howItWork{
    margin-left: 15px;
    margin-right: 15px;
}

.helpForm__wrapper{
    
}

.contactForm{
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    margin-bottom: 20rem;
    height: 15rem;
    padding: 10px;
}


.usr__details{
    flex: 0.5;
    display: block;
}
.message__text{
    flex: 0.5;

}

.usr__details > div > input{
    width:30rem;
    padding: 15px;
    margin-top: 20px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
} 

.message__text > textarea{
   width:30rem;
    padding: 15px;
    margin-top: 20px;
    height: 100px;
    resize: none;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}

.message__text > div > button{
    border: none;
    background-color: #002d65;
    color: white;
    padding: 15px;
    width: 8rem;
    border-radius: 10px;
    font-weight: bolder;
    cursor: pointer;
}

@media screen and (max-width: 1098px){
    .contactForm{
        display: block;
        height: fit-content;
    }

    .message__text > div > button{
        margin-top: 4px;
    }
}

@media screen and (max-width: 860px){
    .top__help{
        display: block;
        text-align: center;
    }
}


@media screen and (max-width: 570px){

    .usr__details > div > input{
        width: 17rem;
    }

    .message__text > textarea{
        width: 17rem;
    }
}