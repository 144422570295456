.main-footer {
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    position: relative;
    bottom: 0;
    width: 100%;
    padding-top: 5px;
    border-top: 2px solid #FF5E00;

  }

.row{
    display: flex;
    justify-content: space-between;
}  
  
.col1{
    margin-left: 10px;
}



.col3{
  margin-left: 0px;
  display: block;
}

.footer__Icons{
    margin-left: 10px;
  
    padding: 2px;
    border-radius: 100px;
}

.lastRow{
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
}

.list-unstyled > li{
    margin-top: 15px;
    list-style: none;
}

#term-of-use{
    list-style: none;
    margin-top: 15px;
}

@media screen and (max-width: 680px) {
    .row{
        display: block;
    }  

    .col1{
        margin-left: 20px;
    }

    .col2{
        margin-left: 20px;
    }

    .col3{
        margin-left: 20px;
    }
}